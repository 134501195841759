/********************************

Reference:
  - xs : '480px',
  - sm : '576px',
  - md : '768px',
  - lg : '992px',
  - xl : '1200px',
  - xxl: '1600px',

********************************/


/*============================
    XXL - Double Extra Large
============================*/
@media screen and (min-width: 1600px) {  /* xxl and up */

}

@media screen and (max-width: 1599px) {  /* bellow xxl */

}


/*============================
    XL - Extra Large
============================*/
@media screen and (min-width: 1200px) {  /* xl and up */

}

@media screen and (max-width: 1199px) {  /* bellow xl */

}


/*============================
    LG - Large
============================*/
 @media screen and (min-width: 992px) {  /* lg and up */

}

@media screen and (max-width: 991px) {  /* bellow lg */

}


/*============================
    MD - Medium
============================*/
@media screen and (min-width: 768px) {  /* md and up */

}

@media screen and (max-width: 767px) {  /* bellow md */

    /* Fix profile wrapper mobile issues in profile and update profile pages */
    .profile-wrapper .div-profile-picture-wrapper {
        width: 100%;
    }
    .profile-wrapper .div-profile-picture-wrapper .div-profile-picture {
        width: 120px;
        left: 0 !important;
    }

}


/*============================
    SM - Small
============================*/
@media screen and (min-width: 576px) {  /* sm and up */

}

@media screen and (max-width: 575px) {  /* bellow sm */

}


/*============================
    XS - Extra Small
============================*/
@media screen and (min-width: 480px) {  /* xs and up */

}

@media screen and (max-width: 479px) {  /* bellow xs */

}
