.ant-pagination {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
}

.ant-pagination ul,
.ant-pagination ol {
    margin: 0;
    padding: 0;
    list-style: none;
}
.ant-pagination::before {
    display: block;
    clear: both;
    color: white;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    content: ' ';
}
.ant-pagination::after {
    display: block;
    clear: both;
    color: white;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    content: ' ';
}

.ant-pagination-total-text {
    display: inline-block;
    height: 32px;
    margin-right: 8px;
    line-height: 30px;
    vertical-align: middle;
}

.ant-pagination-item {
    display: inline-block;
    min-width: 32px;
    height: 35px;
    margin-right: 8px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    line-height: 36px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: cercle;
    outline: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ant-pagination-item a {
    display: block;
    padding: 0 6px;
    color: white;
    transition: none;
}

.ant-pagination-item a:hover {
    color: white;
    text-decoration: none;
}

.ant-pagination-item:hover {
    border-color: #1890ff;
    transition: all 0.3s;
}

.ant-pagination-item:hover a {
    color: white;
    
}

.ant-pagination-item:focus-visible {
    border-color: #1890ff;
    transition: all 0.3s;
    color: white;
}

.ant-pagination-item:focus-visible a {
    color: #1890ff;
    color: white;

}

.ant-pagination-item-active {
    font-weight: 500;
    background: #2a59d1;
    border-color: #1890ff;
    color: white;
}

.ant-pagination-item-active a {
    color: #1890ff;
}

.ant-pagination-item-active:hover {
    color: white;
    border-color: #40a9ff;
    background: #2a59d1;
}

.ant-pagination-item-active:focus-visible {
    border-color: #40a9ff;
    color: white;

}

.ant-pagination-item-active:hover a {
    color: white;
    
    
}

.ant-pagination-item-active:focus-visible a {
    color: #40a9ff;
}

.ant-pagination-jump-prev,
.ant-pagination-jump-next {
    outline: 0;
}

.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
    position: relative;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #1890ff;
    font-size: 12px;
    letter-spacing: -10px;
    opacity: 0;
    transition: all 0.2s;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    margin: auto;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 2px;
    text-align: center;
    text-indent: 0.13em;
    opacity: 1;
    transition: all 0.2s;
}

.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
    opacity: 1;
}

.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
    opacity: 0;
}

.ant-pagination-jump-prev:focus-visible .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus-visible .ant-pagination-item-link-icon {
    opacity: 1;
}

.ant-pagination-jump-prev:focus-visible .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus-visible .ant-pagination-item-ellipsis {
    opacity: 0;
}

.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
    margin-right: 8px;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    line-height: 32px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border-radius: cercle;
    cursor: pointer;
    transition: all 0.3s;
}

.ant-pagination-prev,
.ant-pagination-next {
    font-family: Arial, Helvetica, sans-serif;
    outline: 0;
}

.ant-pagination-prev button,
.ant-pagination-next button {
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ant-pagination-prev:hover button,
.ant-pagination-next:hover button {
    border-color: #40a9ff;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
    line-height: 9px;
    display: block;
    width: 100%;
    height: 110%;
    padding: 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: cercle;
    outline: none;
    transition: all 0.3s;
}

.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link {
    color: #1890ff;
    border-color: #1890ff;
}

.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
    color: #1890ff;
    border-color: #1890ff;
}

.ant-pagination-disabled,
.ant-pagination-disabled:hover {
    cursor: not-allowed;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
}

.ant-pagination-disabled:focus-visible {
    cursor: not-allowed;
}

.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
    color: white;
    border-color: #d9d9d9;
    cursor: not-allowed;
}

.ant-pagination-slash {
    margin: 0 10px 0 5px;
}

.ant-pagination-options {
    display: inline-block;
    margin-left: 16px;
    vertical-align: middle;
}

@media all and (-ms-high-contrast: none) {

    .ant-pagination-options *::-ms-backdrop,
    .ant-pagination-options {
        vertical-align: top;
    }
}

.ant-pagination-options-size-changer.ant-select {
    display: inline-block;
    width: auto;
}

.ant-pagination-options-quick-jumper {
    display: inline-block;
    height: 32px;
    margin-left: 8px;
    line-height: 32px;
    vertical-align: top;
}

.ant-pagination-options-quick-jumper input {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: white;
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
    width: 50px;
    height: 32px;
    margin: 0 8px;
}

.ant-pagination-options-quick-jumper input::-moz-placeholder {
    color: #bfbfbf;
    -moz-user-select: none;
    user-select: none;
}

.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
    color: #bfbfbf;
    -ms-user-select: none;
    user-select: none;
}

.ant-pagination-options-quick-jumper input::placeholder {
    color: #bfbfbf;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ant-pagination-options-quick-jumper input:-moz-placeholder-shown {
    text-overflow: ellipsis;
}

.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
    text-overflow: ellipsis;
}

.ant-pagination-options-quick-jumper input:placeholder-shown {
    text-overflow: ellipsis;
}

.ant-pagination-options-quick-jumper input:hover {
    border-color: #40a9ff;
    border-right-width: 1px;
}

.ant-pagination-options-quick-jumper input:focus,
.ant-pagination-options-quick-jumper input-focused {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    border-right-width: 1px;
    outline: 0;
}

.ant-pagination-options-quick-jumper input-disabled {
    color: white;
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}

.ant-pagination-options-quick-jumper input-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px;
}

.ant-pagination-options-quick-jumper input[disabled] {
    color: white;
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}

.ant-pagination-options-quick-jumper input[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px;
}

.ant-pagination-options-quick-jumper input-borderless,
.ant-pagination-options-quick-jumper input-borderless:hover,
.ant-pagination-options-quick-jumper input-borderless:focus,
.ant-pagination-options-quick-jumper input-borderless-focused,
.ant-pagination-options-quick-jumper input-borderless-disabled,
.ant-pagination-options-quick-jumper input-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.pre-line{
    white-space: 'pre-line';
    color: red;
}

.table-header .search-area {
    width: 350px;
}