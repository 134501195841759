.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading .ant-spin {
  color: #3e79f7;
}

.ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content {
  display: flex;
  align-items: center;
}

/* .ant-menu-title-content {
  line-height: 0em;
} */

/* .ant-btn {
  display: flex !important;
  align-items: center !important;
} */

.ant-tooltip-inner {
  display: flex;
  align-items: center;
  margin-left: 14px;
}

div.ant-tooltip-inner>span:nth-child(2) {
  margin-left: 5px;
}

.search-area {
  max-width: 350px;
}

.wrap-text {
  white-space: pre-line;
}

.ant-layout-sider-collapsed [data-menu-id*="supplier"]>span>span,
.ant-layout-sider-collapsed [data-menu-id*="stores"]>span>span,
.ant-layout-sider-collapsed [data-menu-id*="collections"]>span>span,
.ant-layout-sider-collapsed [data-menu-id*="products"]>span>span {
  display: none;
}

.ant-layout-sider [data-menu-id*="products"]>span>svg {
  font-size: 20px;
  margin-right: 7px;
  margin-left: -2px;
}

.ant-layout-sider [data-menu-id*="collections"]>span>svg {
  font-size: 17px;
  margin-right: 5px !important;
}

.ant-menu-inline>.ant-menu-item,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-left>.ant-menu-item,
.ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-right>.ant-menu-item,
.ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical>.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
  height: 32px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  color: #1a3353;
}


.ant-table-tbody .ant-table-cell {
  padding: 8px 16px;
}

.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label,
.ant-form-vertical .ant-form-item-label {
  padding: 0 !important;
}

.ant-drawer-content-wrapper {
  max-width: 95% !important;
}


.row-clickable {
  cursor: pointer;
}